import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Box,
  Divider,
  Pagination
} from '@mui/material';
import CustomModal from '../../../components/base/CustomModal';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomInput from '../../../components/customInput';
import { useScandiumMutation, useScandiumQuery } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import PageLoader from '../../../components/PageLoader';
import ErrorState from '../../../components/base/ErrorState';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const UpdateSelectorModal = ({ open, onClose, onComplete, selector, refetchTest }) => {
  const [selected, setSelected] = useState([]);
  const [newSelector, setNewSelector] = useState(selector || '');
  const activeProject = useActiveProject();
  const [page, setPage] = useState(1);
  const PAGE_LIMIT = 50;

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const {
    data,
    isLoading: isLoadingSelectors,
    error
  } = useScandiumQuery(`/projects/${activeProject?.id}/test-steps`, {
    enabled: !!activeProject?.id && !!selector,
    params: {
      selector: selector || undefined,
      limit: PAGE_LIMIT,
      page: page || 1
    },
    onSuccess: (response) => {
      const all_data_ids = response.data?.data?.flatMap((item) =>
        item.meta.map((metaItem) => metaItem.id)
      );
      setSelected(all_data_ids);
      setPage(response.data?.currentPage);
    }
  });

  const { mutateAsync: updateSelector, isLoading: isUpdatingSelector } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-steps/update-selector`,
    {
      enabled: !!activeProject?.id && !!selector,
      onSuccess: (response) => {
        toast.success(response.message || 'Selectors updated successfully');
        refetchTest();
        onComplete();
      },
      onError: (error) => {
        toast.error(error?.message || 'Failed to update selectors');
      }
    }
  );

  useEffect(() => {
    if (!!open && selector) {
      setNewSelector(selector);
    }
  }, [selector]);

  const handleUpdateSelector = async () => {
    if (!newSelector) {
      toast.error('Please provide a new selector');
      return;
    }

    if (!selected.length) {
      toast.error('Please select at least one test step');
      return;
    }

    await updateSelector({
      new_selector: newSelector,
      test_steps_ids: selected
    });
  };

  const handleSelectAll = () => {
    const allIds = data?.data?.data?.flatMap((item) => item.meta.map((metaItem) => metaItem.id));

    if (selected.length === allIds.length) {
      setSelected([]);
    } else {
      setSelected(allIds);
    }
  };

  const handleSelectAllTestCaseSteps = (items) => {
    setSelected((prevSelected) => {
      const ids = items.map((item) => item.id);
      const allSelected = ids.every((id) => prevSelected.includes(id));

      return allSelected
        ? prevSelected.filter((id) => !ids.includes(id))
        : [...new Set([...prevSelected, ...ids])];
    });
  };

  const handleSingleId = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const areAllMetaIdsSelected = (data) => {
    return data.every((item) => item.meta.every((metaItem) => selected.includes(metaItem.id)));
  };

  return (
    <CustomModal open={open}>
      <Box
        sx={{
          width: 650,
          borderRadius: 2
        }}>
        <CardContent>
          <Flex justifyContent={'space-between'} sx={{ mb: 1 }}>
            <Typography variant="h4">Test Steps Selector</Typography>
            <IconButton
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: '16px',
                right: '16px'
              }}>
              <CloseIcon
                sx={{
                  fontSize: 25
                }}
              />
            </IconButton>
          </Flex>
          {!isLoadingSelectors && !error && (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Select test cases and provide a new selector for replacements.
              </Typography>

              <CustomInput
                onChange={(e) => {
                  setNewSelector(e.target.value);
                }}
                value={newSelector}
                size={'small'}
                label={'Type or paste new CSS selector'}
                fullWidth
                sx={{ mb: 2 }}
              />

              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                We found <strong>{data?.data?.totalTestCases}</strong> test cases with steps
                matching this target selector. You can select all to replace them or choose specific
                ones to update.
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={areAllMetaIdsSelected(data?.data?.data || [])}
                    onChange={handleSelectAll}
                  />
                }
                label="Select All"
                sx={{ mb: 1 }}
              />

              <List
                sx={{
                  maxHeight: 300,
                  overflowY: 'auto',
                  border: '1px solid #ddd',
                  borderRadius: 1
                }}>
                {data?.data?.data?.map((test, index) => (
                  <React.Fragment key={test._id}>
                    <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 0 }}>
                      <Flex justifyContent={'space-between'} width={'100%'}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={test.meta.every((item) => selected.includes(item.id))}
                              onChange={() => handleSelectAllTestCaseSteps(test.meta)}
                            />
                          }
                          label={test.test_case.name}
                          sx={{ fontWeight: 'bold' }}
                        />

                        <Link
                          to={`/projects/${activeProject?.id}/folders/tests/${test.test_case.id}/edit`}
                          target="_blank"
                          rel="noopener noreferrer">
                          View testcase
                        </Link>
                      </Flex>

                      <List sx={{ pl: 4 }}>
                        {test.meta.map((metaItem, index) => (
                          <React.Fragment key={index}>
                            <ListItem key={index} sx={{ py: 0 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selected.includes(metaItem.id)}
                                    onChange={() => handleSingleId(metaItem.id)}
                                  />
                                }
                                label={`[Step ${metaItem.step_index}] ${metaItem.title}`}
                                sx={{ fontWeight: 'bold' }}
                              />
                            </ListItem>
                          </React.Fragment>
                        ))}
                      </List>
                    </ListItem>
                    {index < data?.data?.data?.length - 1 && <Divider sx={{ my: 1 }} />}
                  </React.Fragment>
                ))}
              </List>
            </>
          )}
        </CardContent>

        {!isLoadingSelectors && !error && (
          <>
            {data?.data?.totalPages > 1 && (
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Pagination
                  count={data?.data?.totalPages}
                  page={page}
                  color="secondary"
                  onChange={handlePageChange}
                  size={'small'}
                  sx={{
                    '& .Mui-selected': {
                      backgroundColor: '#24C3D9',
                      color: '#ffffff'
                    },
                    '& .MuiPaginationItem-root:not(.Mui-selected)': {
                      backgroundColor: '#1958B8',
                      color: '#fff'
                    }
                  }}
                />
              </Box>
            )}

            <Flex sx={{ py: 0 }} columnGap={2} justifyContent={'flex-end'}>
              <OutlinedButton onClick={onClose}>Close</OutlinedButton>
              <ContainedButton
                isLoading={isUpdatingSelector}
                disabled={isUpdatingSelector}
                color={'primary'}
                onClick={handleUpdateSelector}
                autoFocus>
                Replace
              </ContainedButton>
            </Flex>
          </>
        )}
      </Box>

      {isLoadingSelectors && <PageLoader height={'100px'} />}
      {!isLoadingSelectors && !!error && <ErrorState error={error} />}
    </CustomModal>
  );
};

export default UpdateSelectorModal;
