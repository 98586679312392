import { useParams } from 'react-router-dom';
import { useTestRuns } from '../../../data-layer/api-testing';
import { toast } from 'react-toastify';
import { useGetCurrentTest } from '..';
import {
  assertionSelectValidEntries,
  getJSONResponseFields,
  getJSONResponseKeys,
  jsonToObject
} from '../../../pages/ApiSuites/utils';
import { isNumber } from 'lodash';
import prettyBytes from 'pretty-bytes';
import { useSetTestRunResponse, useTableEntriesSetter } from '../updateTestPropsHooks';
import { useSetUIProps } from '../uiPropsHooks';
import { useContext } from 'react';
import { ApiTestContext } from '../../../store/apiState/apiTestContext';

const useRunApiTest = () => {
  const { projectId, testId, newTestId } = useParams();
  const { testPropsForRequest } = useGetCurrentTest();
  const { setApiGlobalProps } = useContext(ApiTestContext);
  const { handleSetTestRunResponse, handleScrollToResponse } = useSetTestRunResponse();
  const { handleFormQueryBodyUpdate } = useTableEntriesSetter();
  const { handleResponseEditorProps } = useSetUIProps();
  const { mutateAsync: runApiTest, isLoading: isRunningApiTest } = useTestRuns({
    onSuccess: (data) => {
      const expectedBlobMime = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'image',
        'jpeg',
        'png',
        'pdf'
      ];
      const runResult = data.data;
      const runResultDataForSaving = JSON.parse(JSON.stringify(runResult));
      let cookieHeader = checkResponseHeadersForCookies(runResult.headers);
      const contentTypeValue = checkResponseHeadersForContentType(runResult.headers);
      let bodyLanguage = contentTypeValue?.split(';')[0].split('/')[1] || 'plaintext';
      // console.log(bodyLanguage);
      bodyLanguage === 'plain' && (bodyLanguage = 'plaintext');
      let responseBody = '';
      let isResponseBodyAFile = false;
      let responseStatusCode;
      let responseSize = 0;
      let responseHeaders = [];
      let responseAssertions = {};
      let responseCookies = [];
      let requestData = {};
      let responseTimeTaken = '';
      if (!runResult.body) {
        responseBody = 'No response body from server';
      }
      requestData = runResult?.request_data;
      requestData.log_error = runResult?.log_error;
      if (isNumber(runResult?.status_code)) {
        if (runResult.status_code === 0) {
          requestData.isError = true;
        }
      }
      if (!!cookieHeader) {
        cookieHeader = cookiesToArray(cookieHeader);
        responseCookies = cookieHeader;
      }
      if (contentTypeValue.includes('json')) {
        responseBody = JSON.stringify(JSON.parse(runResult?.body), null, 2);
        const dataForDynamicJsonFieldHighlight = JSON.parse(runResult?.body.replace(/\s/g, ''));
        handleResponseEditorProps({
          jsonResponseFields: {
            keys: getJSONResponseKeys(dataForDynamicJsonFieldHighlight),
            fields: getJSONResponseFields(dataForDynamicJsonFieldHighlight)
          }
        });
      } else {
        responseBody = runResult?.body;
      }
      expectedBlobMime.forEach((format) => {
        if (contentTypeValue.includes(format)) {
          isResponseBodyAFile = true;
          responseBody = runResult.body.replace(/\s/g, '');
        }
        return format;
      });
      if (!!runResult?.headers) {
        responseHeaders = runResult?.headers;
      }
      if (!!runResult?.size) {
        const _size = +runResult?.size;
        responseSize = _size;
      }
      responseStatusCode = runResult?.status_code;
      if (!!runResult?.assertions) {
        responseAssertions = runResult?.assertions;
      }

      responseTimeTaken = runResult?.time_taken;
      //requestData for formData
      //refetchGlobalVariables
      // responseEditorState, consoleData
      let responseData = {
        hasResponse: true,
        runResultDataForSaving,
        responseBody,
        isResponseBodyAFile,
        responseStatusCode,
        responseSize,
        responseHeaders,
        responseAssertions,
        responseCookies,
        responseTimeTaken,
        contentTypeValue
      };
      // swap base64 string in current test formData Body with cloud url.
      if (requestData.mode === 'formData' && requestData?.body?.length > 0) {
        let _obj = [...testPropsForRequest.formData];
        const requestBodyWithFile = requestData.body.filter((requests) => {
          return requests.type === 'file';
        });
        const newFormData = _obj.map((entry) => {
          requestBodyWithFile.map((request) => {
            if (entry.id === request.id) {
              entry.value = request.value;
            }
          });
          return entry;
        });
        handleFormQueryBodyUpdate(newFormData);
        // setFormData(newFormData);
      }
      handleScrollToResponse();
      handleResponseEditorProps({
        responseEditorLanguage: bodyLanguage
      });
      handleSetTestRunResponse(responseData);
      // console.log(responseData);
      setApiGlobalProps((prev) => {
        const prevConsoleData = [...prev.consoleData];
        return { ...prev, consoleData: [...prevConsoleData, requestData] };
      });
    },
    onError: (error) => {
      let responseData = {
        hasResponse: true,
        runResultDataForSaving: {
          assertions: {},
          headers: [],
          size: 0,
          status_code: 0,
          time_taken: 0,
          body: error?.message || 'Failed to run test'
        },
        responseBody: JSON.stringify(error, null, 2),
        isResponseBodyAFile: false,
        responseStatusCode: error?.data?.status || 0,
        responseSize: 0,
        responseHeaders: [],
        responseAssertions: {},
        responseCookies: [],
        responseTimeTaken: 0,
        contentTypeValue: ''
      };
      handleScrollToResponse();
      handleResponseEditorProps({
        responseEditorLanguage: 'plaintext'
      });
      handleSetTestRunResponse(responseData);
    }
  });

  const handleRunApiTest = async () => {
    const testProps = configureTestRequestData(testPropsForRequest);
    if (!testProps?.data?.url) {
      toast.error('Please enter a request url');
      return;
    }
    try {
      await runApiTest({
        projectId,
        data: testProps,
        testId: testId ? testId : null
      });
    } catch (e) {
      // console.log(e);
      toast.error('Failed to run test. Please try again');
    }
  };
  return { handleRunApiTest, isRunningApiTest };
};

export const checkResponseHeadersForContentType = (headersArray) => {
  const contentTypeHeader = headersArray?.find((header) => {
    return header.key.toLowerCase() === 'content-type';
  });
  let contentTypeValue = contentTypeHeader?.value || 'application;text/html';
  return contentTypeValue;
};
export const checkResponseHeadersForCookies = (headersArray) => {
  const cookiesHeader = headersArray?.find((header) => {
    return header.key.toLowerCase() === 'set-cookie';
  });
  return cookiesHeader;
};
export const cookiesToArray = (cookieString) => {
  let splitValues = cookieString.value.split(';');
  let cookiesArray = [];
  splitValues.forEach((cookieField) => {
    const [key, value] = cookieField.split('=');
    let cookiesEntry = { key: key || '', value: value || '' };
    cookiesArray.push(cookiesEntry);
  });
  return cookiesArray;
};

export const configureTestRequestData = (testPropsForRequest) => {
  // console.log('tried to configure');
  let {
    url,
    headers,
    params,
    cookies,
    authorizationHeader,
    requestMethod,
    requestBodyMode,
    assertionsSelect,
    rawBodyLanguage
  } = testPropsForRequest;
  const validHeaders = headers?.filter((header) => header.key && header.value);
  const validCookies = cookies?.filter((param) => param.key && param.value);
  const validParams = params?.filter((param) => {
    return (param.key && param.value) || param.src === 'authorization';
  });

  const authorization = JSON.stringify({
    value: authorizationHeader
  });
  const { requestBody } = configureTestRequestBody(testPropsForRequest);
  const testAssertions = assertionSelectValidEntries(assertionsSelect);
  const testData = {
    url,
    authorization,
    request_method: requestMethod.toLowerCase(),
    headers: validHeaders,
    params: validParams,
    cookies: validCookies,
    mode: requestBodyMode === 'raw' ? rawBodyLanguage : requestBodyMode,
    body: requestBody
  };
  return { data: testData, assertions: testAssertions };
};

const configureTestRequestBody = (testPropsForRequest) => {
  let requestBody = {}; // whenever requstbody is "" and the bodyType is formData, B.E do not save, throws an error
  const {
    requestBodyMode,
    formData,
    formUrlEncoded,
    rawBodyLanguage,
    rawRequestBody,
    graphQLVariables
  } = testPropsForRequest;

  const validFormData = formData?.filter((entry) => entry.key && entry.value);
  const validFormUrlEncoded = formUrlEncoded?.filter((entry) => entry.key && entry.value);

  if (requestBodyMode === 'urlencoded' && validFormUrlEncoded.length > 0) {
    requestBody = validFormUrlEncoded;
  } else if (requestBodyMode === 'formData' && validFormData.length > 0) {
    requestBody = validFormData;
  } else if (requestBodyMode === 'raw') {
    if (rawBodyLanguage === 'json') {
      requestBody = jsonToObject(rawRequestBody);
    } else if (rawBodyLanguage === 'graphql') {
      requestBody = {};
      requestBody.query = rawRequestBody;
      requestBody.variables = jsonToObject(graphQLVariables) || {};
    } else {
      requestBody = rawRequestBody;
    }
  } else if (requestBodyMode === 'none') {
    requestBody = '';
  }
  return { requestBody };
};

export default useRunApiTest;
