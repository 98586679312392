import EventCard, { eventCardSx, GroupCard } from './EventCard';
import Flex from '../../../components/base/Flex';
import { useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TestRunSettingsBar from './TestRunSettingsBar';
import EmptyState from '../../../components/base/EmptyState';
import ErrorState from '../../../components/base/ErrorState';
import StepDetails from './StepDetails';
import CustomScrollbar from './CustomScrollbar';
import DraggableCard from './DraggableCard';
import { StatusBadge } from './StatusBadge';
import Box from '@mui/material/Box';
import PreconditionCustomExpressionModal from './PreconditionCustomExpressionModal';
import useAwaitModal from '../../../hooks/useAwaitModal';
import CustomJavascriptStep from './CustomJavascriptStep';
import UpdateSelectorModal from './UpdateSelectorModal';

const TestPageBody = ({
  events,
  testGroups,
  error,
  refetchTest,
  runResults,
  testTitle,
  onUpdateEvent,
  onUpdateGroup,
  handleDelete,
  afterGroupDuplicated,
  handleRecord,
  startPlayback,
  setSelectedSteps,
  selectedSteps,
  onToggleStepDetails,
  onReorderSteps,
  insertEventAt,
  onDragEnd,
  testCaseVariables,
  dataSource,
  onGroupAssociated,
  handleUpdateEventScreenshot,
  consoleData,
  globalVariables,
  failureTag,
  refetchPreviousRuns,
  sessionId,
  addCustomJavascriptStep
}) => {
  // The step whose actions are expanded. We want to control that from here to prevent multiple steps having their options expanded, which is confusing
  const [expandedActionsStepId, setExpandedActionsStepId] = useState(null);

  // `active` in this context means the settings bar is open with this step's settings
  const [activeEventIndex, setActiveEventIndex] = useState(null);
  // the active Event Index inside an activeEvent that is a group
  const [activeGroupStepIndex, setActiveGroupStepIndex] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  // Storing this as a coordinate, allows us to manipulate the navigation easily without keeping track of too many fields.
  //  This also allows us to extend this to support nested groups in the future.
  const [stepDetailsCoordinates, setStepDetailsCoordinates] = useState({
    index: null,
    parent: null
  });

  // Expanded group is responsible for the group whose steps are expanded
  const [expandedGroup, setExpandedGroup] = useState(null);

  const isShowingSettings = !!events && activeEventIndex !== null;

  // Same as the list of events, but with group details added to each group event
  const eventsWithGroupDetails = useMemo(() => {
    return events
      .map((event) => {
        if (event.type !== 'group') return event;
        const group = testGroups.find((g) => g.id === event.id);
        return { ...event, ...group };
      })
      .filter((event) => (event.type !== 'group' || event.steps?.length > 0) && !event.isHidden);
  }, [events, testGroups]);

  const findCardIndex = useCallback(
    (id) => {
      return events.findIndex((event) => event.id === id);
    },
    [events]
  );

  const [
    requestCustomExpressionPreconditionModal,
    {
      open: openCustomExpressionPreconditionModal,
      onClose: onCloseCustomExpressionPreconditionModal,
      onComplete: completeCustomExpressionPreconditionModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const [
    requestJavascriptStepModal,
    {
      open: openJavascriptStepModal,
      onClose: onCloseJavascriptStepModal,
      onComplete: completeJavascriptStepModal,
      ...otherJavascriptStepProps
    }
  ] = useAwaitModal();

  const [
    requestUpdateSelectorModal,
    {
      open: updateSelectorOpen,
      onClose: onCloseUpdateSelector,
      onComplete: completeUpdateSelectorModal,
      ...otherUpdateSelectorProps
    }
  ] = useAwaitModal();

  if (!Array.isArray(events)) return null;

  const activeEvent =
    isShowingSettings &&
    (eventsWithGroupDetails[activeEventIndex]?.type === 'group'
      ? eventsWithGroupDetails[activeEventIndex].steps?.[activeGroupStepIndex] ||
        eventsWithGroupDetails[activeEventIndex]
      : eventsWithGroupDetails[activeEventIndex]);

  const activeEventParentGroup =
    activeEvent?.type !== 'group' && eventsWithGroupDetails[activeEventIndex]?.type === 'group'
      ? eventsWithGroupDetails[activeEventIndex]
      : null;

  const isShowingDetails = stepDetailsCoordinates.index !== null;

  const detailsEvent =
    isShowingDetails && stepDetailsCoordinates.parent
      ? eventsWithGroupDetails[stepDetailsCoordinates.parent].steps?.[stepDetailsCoordinates.index]
      : eventsWithGroupDetails[stepDetailsCoordinates.index];

  const detailsStepParentGroup = eventsWithGroupDetails[stepDetailsCoordinates.parent] || null;

  const handleSetActiveEventIndex = (index = null, groupIndex = null) => {
    if (groupIndex !== null) {
      setActiveEventIndex(groupIndex);
      setActiveGroupStepIndex(index);
    } else {
      setActiveEventIndex(index);
    }
  };

  const runResultsById = (runResults || []).reduce(
    (acc, result) => ({ ...acc, [result.step_id || result.id]: result }),
    {}
  );

  const handleToggleStepDetails = (coordinates = { index: null, parent: null }) => {
    setStepDetailsCoordinates(coordinates);
    // if we were showing settings, we want to show settings for the new coordinates
    if (isShowingSettings) {
      handleSetActiveEventIndex(coordinates.index, coordinates.parent);
    }
    onToggleStepDetails(coordinates.index !== null);
    // handleStepIdParams(coordinates.index)
  };

  // Next is disabled if there are no forward steps
  const nextDetailDisabled = !detailsStepParentGroup
    ? events.length - 1 === stepDetailsCoordinates.index
    : detailsStepParentGroup.steps.length - 1 === stepDetailsCoordinates.index &&
      events.length - 1 === stepDetailsCoordinates.parent;

  const prevDetailDisabled = !detailsStepParentGroup
    ? stepDetailsCoordinates.index === 0
    : stepDetailsCoordinates.index === 0 && stepDetailsCoordinates.parent === 0;

  const goToNextStepDetails = () => {
    if (
      detailsStepParentGroup &&
      stepDetailsCoordinates.index + 1 < detailsStepParentGroup.steps.length
    ) {
      return handleToggleStepDetails({
        ...stepDetailsCoordinates,
        index: stepDetailsCoordinates.index + 1
      });
    } else {
      const nextIndex = detailsStepParentGroup
        ? stepDetailsCoordinates.parent + 1
        : stepDetailsCoordinates.index + 1;
      if (nextIndex < events.length) {
        return handleToggleStepDetails({
          index: eventsWithGroupDetails[nextIndex].type === 'group' ? 0 : nextIndex,
          parent: eventsWithGroupDetails[nextIndex].type === 'group' ? nextIndex : null
        });
      }
    }
  };

  const goToPrevStepDetails = () => {
    const prevIndex = stepDetailsCoordinates.index - 1;
    // if it's on a group and still within scope
    if (detailsStepParentGroup) {
      return handleToggleStepDetails(
        prevIndex >= 0
          ? {
              ...stepDetailsCoordinates,
              index: prevIndex
            }
          : {
              index: Math.max(stepDetailsCoordinates.parent - 1, 0),
              parent: null
            }
      );
    }

    if (prevIndex >= 0) {
      return handleToggleStepDetails(
        eventsWithGroupDetails[prevIndex].type === 'group'
          ? {
              index: eventsWithGroupDetails[prevIndex].steps.length - 1,
              parent: prevIndex
            }
          : {
              index: prevIndex,
              parent: null
            }
      );
    }
  };

  const errorStep = runResults
    ?.slice()
    .reverse()
    .find((item) => item.status === 'error');
  const failedStepIndex = findCardIndex(errorStep?.step_id);

  // We to confirm that the error step has not been deleted, this will will guard against bug when trying to view the step details of a deleted error step from the "see error" button.
  const hasId = events?.some((item) => item.id === errorStep?.step_id);

  return (
    <>
      {errorStep && hasId && !isShowingDetails && (
        <Box my={1}>
          <StatusBadge
            status={errorStep?.status}
            index={failedStepIndex}
            error={errorStep?.error}
            failureTag={failureTag}
            refetchPreviousRuns={refetchPreviousRuns}
            sessionId={sessionId}
            onViewDetails={() => handleToggleStepDetails({ index: failedStepIndex, parent: null })}
            errorStep={errorStep}></StatusBadge>
        </Box>
      )}
      <Flex alignItems={'flex-start'} py={isShowingDetails ? 0 : 4} columnGap={2}>
        {isShowingDetails ? (
          <StepDetails
            testTitle={testTitle}
            onClose={() => handleToggleStepDetails()}
            event={{
              ...detailsEvent,
              title: `${(stepDetailsCoordinates.parent || stepDetailsCoordinates.index) + 1}. ${
                detailsStepParentGroup
                  ? `${detailsStepParentGroup.title} / ${stepDetailsCoordinates.index + 1}. ${
                      detailsEvent.title
                    }`
                  : detailsEvent.title
              }`
            }}
            runResult={runResultsById[detailsEvent?.id || errorStep.step_id]}
            onOpenSettings={
              !!onUpdateEvent &&
              (() =>
                activeEventIndex !== null
                  ? handleSetActiveEventIndex(null)
                  : handleSetActiveEventIndex(
                      stepDetailsCoordinates.index,
                      stepDetailsCoordinates.parent
                    ))
            }
            onNext={!nextDetailDisabled && goToNextStepDetails}
            onPrev={!prevDetailDisabled && goToPrevStepDetails}
            handleUpdateEventScreenshot={handleUpdateEventScreenshot}
            logData={consoleData}
          />
        ) : (
          <CustomScrollbar
            height={isShowingSettings ? '1048px' : 'inherit'}
            sx={{
              flex: 1,
              overflowY: isShowingSettings ? 'auto' : 'inherit',
              overflowX: isShowingSettings ? 'hidden' : 'inherit'
            }}>
            <Flex
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              position={'relative'}
              flexWrap={'wrap'}
              columnGap={0}
              sx={{ ml: -1, mr: -1, mt: -2, flex: 1 }}>
              {eventsWithGroupDetails.map((event, index) => (
                <DraggableCard
                  id={event.id}
                  index={index}
                  moveCard={onReorderSteps}
                  type={index === 0 ? 'disabled' : 'moveable'}
                  onDragEnd={onDragEnd}
                  findCardIndex={findCardIndex}
                  sx={eventCardSx}>
                  {event.type === 'group' ? (
                    <GroupCard
                      group={{
                        ...event,
                        ...runResultsById[event.id],
                        title: `${index + 1}. ${event.title}`
                      }}
                      runResultsById={runResultsById}
                      onExpand={() =>
                        expandedGroup === index ? setExpandedGroup(null) : setExpandedGroup(index)
                      }
                      onUpdateGroup={onUpdateGroup}
                      steps={event.steps || []}
                      onViewStepDetails={(stepIndex) =>
                        handleToggleStepDetails({ index: stepIndex, parent: index })
                      }
                      isExpanded={expandedGroup === index}
                      onExpandSettings={
                        !!onUpdateEvent &&
                        ((stepIndex = null) => handleSetActiveEventIndex(stepIndex, index))
                      }
                      isViewingSettings={activeEvent?.id === event.id}
                      // A group can has multiple steps and we want to be able to expand actions on either the group or its steps
                      expandedActionsStepId={expandedActionsStepId}
                      onExpandActions={(id) => setExpandedActionsStepId(id)}
                      activeSettingStepId={activeEvent?.id}
                      onStartPlayback={(stepIndexInGroup) => startPlayback(index, stepIndexInGroup)}
                      onStartRecording={!!onUpdateEvent && (() => handleRecord(index + 1))}
                      onSelect={(selected) => {
                        if (selected) return setSelectedSteps([...selectedSteps, index]);
                        const selectedIndex = selectedSteps.indexOf(index);
                        if (selectedIndex > -1)
                          return setSelectedSteps([
                            ...selectedSteps.slice(0, selectedIndex),
                            ...selectedSteps.slice(selectedIndex + 1)
                          ]);
                      }}
                      isSelected={selectedSteps.includes(index)}
                      key={index}
                      onReorder={onReorderSteps}
                      index={index}
                      onAddJavascriptStep={() =>
                        requestJavascriptStepModal({
                          addJavascriptStep: (title, expression) =>
                            addCustomJavascriptStep(index + 1, title, expression)
                        })
                      }
                    />
                  ) : (
                    <EventCard
                      isActionsExpanded={expandedActionsStepId === event.id}
                      onExpandActions={(expand) =>
                        setExpandedActionsStepId(expand ? event.id : null)
                      }
                      isFirstStep={index === 0}
                      onExpandSettings={!!onUpdateEvent && (() => handleSetActiveEventIndex(index))}
                      // Gradually migrating towards a separate run results object
                      event={{
                        ...event,
                        ...runResultsById[event.id],
                        screenshot: event.screenshot || runResultsById[event.id]?.result_screenshot,
                        title: `${index + 1}. ${event.title}`
                      }}
                      onViewDetails={() => handleToggleStepDetails({ index, parent: null })}
                      isActive={activeEventIndex === index}
                      onStartPlayback={() => startPlayback(index)}
                      onStartRecording={!!onUpdateEvent && (() => handleRecord(index + 1))}
                      onSelect={(selected) => {
                        if (selected) return setSelectedSteps([...selectedSteps, index]);
                        const selectedIndex = selectedSteps.indexOf(index);
                        if (selectedIndex > -1)
                          return setSelectedSteps([
                            ...selectedSteps.slice(0, selectedIndex),
                            ...selectedSteps.slice(selectedIndex + 1)
                          ]);
                      }}
                      isSelected={selectedSteps.includes(index)}
                      key={index}
                      onReorder={onReorderSteps}
                      index={index}
                      onAddJavascriptStep={() =>
                        requestJavascriptStepModal({
                          addJavascriptStep: (title, expression) =>
                            addCustomJavascriptStep(index + 1, title, expression)
                        })
                      }
                    />
                  )}
                </DraggableCard>
              ))}

              {!!error ? (
                <ErrorState error={error} />
              ) : (
                !events.length && (
                  <EmptyState
                    description={
                      'Tests are more useful with assertions. Try recording a few steps above'
                    }
                  />
                )
              )}
            </Flex>
          </CustomScrollbar>
        )}
        {isShowingSettings && (
          <TestRunSettingsBar
            testGroups={testGroups}
            globalVariables={globalVariables}
            // onGroupAssociated={onGroupAssociated}
            onGroupAssociated={(group, didAddSteps, insertAfter) => {
              return onGroupAssociated(group, [activeEventIndex], didAddSteps, insertAfter);
            }}
            key={`${activeEventIndex}-${activeEvent?.id}`}
            activeEventIndex={activeEventIndex}
            event={activeEvent}
            events={eventsWithGroupDetails}
            testCaseVariables={testCaseVariables}
            dataSource={dataSource}
            parentGroup={activeEventParentGroup}
            onClose={() => handleSetActiveEventIndex(null)}
            refetchTest={refetchTest}
            onUpdateEvent={onUpdateEvent}
            onUpdateGroup={onUpdateGroup}
            afterGroupDuplicated={afterGroupDuplicated}
            pasteEventInParent={(event) => {
              // the event is the activeEvent. It will always be a step in a group
              // insert event at activeEventIndex + 1
              insertEventAt(event, activeEventIndex + 1);
              // update the settings bar to show the newly inserted event
              handleSetActiveEventIndex(activeEventIndex + 1, null);
            }}
            afterDelete={() => {
              if (isShowingDetails) {
                if (events.length <= 1) return handleToggleStepDetails();
                // Handle differently for steps in groups
                // if step in group, check if it's the last step in the group, if so, go to next detail if !nextDetailDisabled, else go to prev detail if !prevDetailDisabled, else close details
                if (detailsStepParentGroup) {
                  if (stepDetailsCoordinates.index === detailsStepParentGroup.steps.length - 1) {
                    if (!nextDetailDisabled) {
                      // Ideally, keeping the active index the same is safe (the next step becomes the current step), but because for steps in group, the coordinates are slightly different, staying the same will be catastrophic.
                      return goToNextStepDetails();
                    } else if (!prevDetailDisabled) {
                      return goToPrevStepDetails();
                    }
                  }
                }
                if (nextDetailDisabled) return goToPrevStepDetails();
              }
              handleSetActiveEventIndex(null);
            }}
            handleDelete={() => {
              handleDelete(activeEventIndex);
            }}
            requestCustomExpressionPreconditionModal={requestCustomExpressionPreconditionModal}
            requestJavascriptStepModal={requestJavascriptStepModal}
            requestUpdateSelectorModal={(selector) =>
              requestUpdateSelectorModal({ refetchTest, selector })
            }
            sx={{ transition: 'width 0.5s ease-in-out' }}
          />
        )}
      </Flex>

      <PreconditionCustomExpressionModal
        open={openCustomExpressionPreconditionModal}
        onClose={onCloseCustomExpressionPreconditionModal}
        onComplete={completeCustomExpressionPreconditionModal}
        {...otherProps}
      />

      <CustomJavascriptStep
        open={openJavascriptStepModal}
        onClose={onCloseJavascriptStepModal}
        onComplete={completeJavascriptStepModal}
        {...otherJavascriptStepProps}
      />

      <UpdateSelectorModal
        open={updateSelectorOpen}
        onClose={onCloseUpdateSelector}
        onComplete={completeUpdateSelectorModal}
        {...otherUpdateSelectorProps}
      />
    </>
  );
};

export default TestPageBody;
