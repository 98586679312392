import { useNavigate, useParams } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumQuery } from '../../../data-layer/utils';
import { useContext } from 'react';
import { ApiTestContext, testCaseAuthObject } from '../../../store/apiState/apiTestContext';
import {
  configureAssertions,
  convertNullValuesToEmptyString,
  formatDate,
  generateRandomNewTest10Digits
} from '../../../pages/ApiSuites/utils';
import { toast } from 'react-toastify';

const useFetchSingleTest = () => {
  const { exampleId, testId } = useParams();
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const { setTestsTab, enableTestFetch, setEnableTestFetch, testsTab } = useContext(ApiTestContext);
  const triggerQuery = () => {
    return !!activeProject?.id && (!!testId || (!!exampleId && !!testId)) && !!enableTestFetch;
  };

  //<----if not fetch the test, add it to the list and navigate to it's testId    --->//
  let endpoint = `/projects/${activeProject?.id}/api-tests/${testId}`;
  if (!!exampleId) {
    endpoint = `/projects/${activeProject?.id}/api-tests/${testId}/examples/${exampleId}`;
  }
  const {
    isSuccess: singleTestFetched,
    isLoading: isLoadingSingleTest,
    isFetching: isFetchingSingleTest,
    error: fetchSingleTestError,
    refetch: refetchActiveTest
  } = useScandiumQuery(endpoint, {
    enabled: triggerQuery(),
    queryKey: ['get-single-test'],
    onSuccess: (data) => {
      setEnableTestFetch(false);
      const fetchedTest = data?.data;
      // console.log(fetchedTest);
      const fetchedTestResponse = fetchedTest?.response;
      const rawBodyTypes = ['plaintext', 'json', 'javascript', 'html', 'xml', 'graphql'];
      const formBodyTypes = ['urlencoded', 'formData'];
      //------------data for test response config----------------//
      let testDocumentData = {
        url: '',
        method: '',
        reqParams: [],
        reqHeaders: [],
        reqBody: '',
        mode: '',
        authType: ''
      };
      let fetchedTestResponseData = {
        responseBody: '',
        responseStatusCode: '',
        responseSize: '',
        responseHeaders: [],
        responseAssertions: {},
        responseCookies: [],
        // requestData: fetchedTestResponse?.request_data || {},
        responseTimeTaken: ''
      };
      // if (!!fetchedTestResponse) {
      // const isPostManResponseHeaders = !Array.isArray(fetchedTestResponse?.headers);
      // if (!!isPostManResponseHeaders) {
      //   const arrayHeaders = Object.entries(fetchedTestResponse?.headers).map(([key, value]) => {
      //     return { key: key, value: value[0] };
      //   });
      //   fetchedTestResponse.headers = arrayHeaders;
      // }
      // fetchedTestResponseData = {
      //   responseBody: '', // response body is not saved
      //   responseStatusCode: '',
      //   responseSize: '',
      //   responseHeaders: [],
      //   responseAssertions: {},
      //   responseCookies: [],
      //  requestData: fetchedTestResponse?.request_data || {},
      //   responseTimeTaken: ''
      // };
      // }
      //-------------data for test body config------------------//
      let queryParams = [{ key: '', value: '', description: '' }];
      let queryHeaders = [{ key: '', value: '', description: '' }];
      let queryCookies = [{ key: '', value: '', expires_at: '' }];
      let authorizationHeader = JSON.parse(JSON.stringify(testCaseAuthObject));
      let pathVariables = [];
      let requestBodyMode = 'none'; // raw, formdat, urlencoded
      let rawBodyLanguage = 'json';
      let assertionsSelect = [];
      let rawRequestBody = '';
      let formData = [{ key: '', value: '', type: 'text', filename: '', description: '' }];
      let formUrlEncoded = [{ key: '', value: '', description: '' }];
      let graphQLVariables = '';
      const isPostManTest =
        fetchedTest.data.body?.mode || typeof fetchedTest?.data?.url === 'object';

      if (fetchedTest?.data.cookies) {
        // run a function to convert null values to ''
        queryCookies = [...fetchedTest?.data?.cookies, ...queryCookies];
        queryCookies = convertNullValuesToEmptyString([...queryCookies]);
      }
      if (fetchedTest?.data.params) {
        // run a function to convert null values to ''
        fetchedTest.data.params = convertNullValuesToEmptyString(fetchedTest?.data?.params);
        queryParams = [...fetchedTest?.data?.params, ...queryParams];
        queryParams = convertNullValuesToEmptyString([...queryParams]);
      }
      if (fetchedTest?.data.headers) {
        // run a function to convert null values to ''
        // console.log(fetchedTest.data.headers);
        queryHeaders = [...fetchedTest?.data?.headers, ...queryHeaders];
        queryHeaders = convertNullValuesToEmptyString([...queryHeaders]);
      }
      if (fetchedTest?.data.pathVariables) {
        let newPathVariables = fetchedTest?.data.pathVariables.map((item) => {
          return {
            ...item,
            value: item.value === null ? '' : item.value,
            description: item.description === null ? '' : item.description
          };
        });
        pathVariables = newPathVariables;
      }
      if (fetchedTest?.assertions) {
        assertionsSelect = configureAssertions(fetchedTest.assertions);
      }
      if (fetchedTest?.data?.mode) {
        if (rawBodyTypes.includes(fetchedTest?.data?.mode)) {
          requestBodyMode = 'raw';
          rawBodyLanguage = fetchedTest?.data?.mode;
        } else if (formBodyTypes.includes(fetchedTest?.data.mode)) {
          requestBodyMode = fetchedTest?.data?.mode;
        }
      }
      if (fetchedTest?.data?.body && !isPostManTest) {
        if (rawBodyTypes.includes(fetchedTest?.data?.mode)) {
          if (fetchedTest?.data?.mode === 'json') {
            rawRequestBody = JSON.stringify(fetchedTest?.data?.body, null, 2); // it's saved on backend a js object but the editor expects a json string
          } else if (fetchedTest?.data?.mode === 'graphql') {
            rawRequestBody = fetchedTest?.data?.body?.query;
            graphQLVariables = JSON.stringify(fetchedTest?.data?.body?.variables); // it's saved on backend a js object but the editor expects a json string
          } else {
            rawRequestBody = fetchedTest?.data?.body; // other raw types were already saved as strings
          }
        } else if (formBodyTypes.includes(fetchedTest?.data.mode)) {
          if (fetchedTest?.data?.mode === 'formData') {
            formData =
              fetchedTest.data.body.length > 0
                ? [...fetchedTest?.data?.body, ...formData]
                : formData;
          }
          if (fetchedTest?.data?.mode === 'urlencoded') {
            formUrlEncoded =
              fetchedTest.data.body.length > 0
                ? [...fetchedTest?.data?.body, ...formUrlEncoded]
                : formUrlEncoded;
          }
        }
      }
      if (!!fetchedTest.data.authorization) {
        if (typeof fetchedTest.data.authorization === 'object') {
          return; // for auth formerly saved as objects
        } else {
          const authObj = JSON.parse(fetchedTest.data.authorization);
          // documentData.authType = authObj?.value?.option;
          authorizationHeader = { ...authorizationHeader, ...authObj.value };
        }
      }
      if (isPostManTest) {
        // console.log(fetchedTest?.data?.url);
        requestBodyMode = fetchedTest.data.body?.mode;
        if (fetchedTest.data.body?.mode === 'raw') {
          let pmRawLanguage = fetchedTest.data?.body?.options?.raw?.language;
          let pmRawBody = fetchedTest.data?.body?.raw;
          rawBodyLanguage = pmRawLanguage;
          if (pmRawLanguage === 'json') {
            rawRequestBody = pmRawBody.replace(/\n/g, '');
          } else {
            rawRequestBody = pmRawBody;
          } // check for graphql also
        }
        if (fetchedTest.data.body?.mode === 'graphql') {
          rawRequestBody = fetchedTest.data.body?.graphql?.query;
          graphQLVariables = fetchedTest?.data?.body?.graphql?.variables?.replace(/\n/g, '');
        }

        if (requestBodyMode === 'urlencoded') {
          formUrlEncoded = [...fetchedTest.data?.body?.urlencoded, ...formUrlEncoded]; //check or confirm this spread result
        }
        if (requestBodyMode === 'formData') {
          const newFormData = fetchedTest?.data?.body?.formdata.map((entry) => {
            return { key: entry.key, type: entry.type, value: entry.value || '' };
          });
          formData = [...newFormData, ...formData]; //check or confirm this spread result
        }
      }
      testDocumentData.reqHeaders = queryHeaders.filter((header) => !!header.key && !!header.value);
      testDocumentData.reqParams = queryParams.filter((param) => !!param.key && !!param.value);
      testDocumentData.authType = authorizationHeader?.option;
      testDocumentData.url = fetchedTest?.data?.url;
      testDocumentData.method = fetchedTest?.data?.request_method?.toUpperCase();
      testDocumentData.mode = requestBodyMode;
      if (requestBodyMode === 'raw') {
        testDocumentData.mode = rawBodyLanguage;
      }
      if (requestBodyMode === 'formData') {
        testDocumentData.reqBody = formData;
      } else if (requestBodyMode === 'urlencoded') {
        testDocumentData.reqBody = formUrlEncoded.filter((entry) => !!entry.key && !!entry.value);
      } else {
        testDocumentData.reqBody = rawRequestBody;
      }
      //////response editor something
      const newTestObj = {
        testPropsForRequest: {
          url: !isPostManTest
            ? fetchedTest?.data?.url
            : fetchedTest?.data?.url?.raw ||
              fetchedTest?.data?.url?.host ||
              fetchedTest?.data?.url ||
              '', // non get methods in post man have their url in data.url not .host/.raw
          params: queryParams,
          headers: queryHeaders,
          cookies: queryCookies,
          pathVariables,
          rawRequestBody, // i.e rawContent
          requestBodyMode: requestBodyMode,
          requestMethod: fetchedTest?.data?.request_method?.toUpperCase(),
          authorizationHeader,
          assertionsSelect,
          rawBodyLanguage: rawBodyLanguage,
          formData: formData,
          formUrlEncoded: formUrlEncoded,
          graphQLVariables
        },
        testRunResponse: {
          hasResponse: false,
          ...fetchedTestResponseData
        },
        testDetails: {
          name: fetchedTest?.name || '',
          id: fetchedTest?.id || fetchedTest?.uuid || '',
          'Created on': !!fetchedTest?.uuid ? '' : formatDate(fetchedTest?.created_at),
          'Updated on': !!fetchedTest?.uuid ? '' : formatDate(fetchedTest?.updated_at),
          Creator: fetchedTest?.creator?.name,
          parentId: fetchedTest?.api_test_case_id || null
          // testDescription: fetchedTest?.documentationS
        },
        uiProps: {
          testAgent: {
            runAgent: '',
            agentPort: '',
            checkIfAgentIsOn: async () => {}
          },
          toolBar: {
            activeTool: null
          },
          testPropsTab: 0,
          testResponseTab: 0,
          assertionsFilterTab: 0,
          responseEditorProps: {
            wordWrap: 'on',
            responseEditorLanguage: 'json',
            lineNumbers: 'on',
            value: '',
            activeBTN: 'pretty',
            jsonResponseFields: {
              keys: [],
              fields: []
            }
          },
          testDocumentData
        },
        hasNewChanges: false,
        testType: !!fetchedTest?.uuid ? 'api-test-example' : 'api-test'
      };
      let copyOfFetchedTestProps = JSON.parse(
        JSON.stringify({
          ...newTestObj?.testPropsForRequest,
          testName: { ...newTestObj }?.testDetails.name
          // runResponse: { ...newTestObj.testRunResponse } run response data is not being saved so it is not tracked
        })
      );
      newTestObj.testInitialData = copyOfFetchedTestProps;
      const formerTest = testsTab.testsList.find((test) => {
        return test.testDetails.id === newTestObj.testDetails.id;
      });
      if (!!formerTest) {
        // console.log('it is a refetch replace the previous test in the tab');
        setTestsTab((prev) => {
          const newList = prev.testsList?.map((test) => {
            if (test.testDetails.id === formerTest.testDetails.id) {
              return newTestObj;
            } else {
              return test;
            }
          });
          return {
            ...prev,
            testsList: [...newList],
            currentTabId: newTestObj.testDetails.id
          };
        });
        return;
      }
      setTestsTab((prev) => {
        // if it's a refetch, replace the test with the new details
        return {
          ...prev,
          testsList: [...prev.testsList, newTestObj],
          currentTabIndex: !prev?.testsList.length ? 0 : prev?.testsList?.length,
          currentTabId: newTestObj.testDetails.id
        };
      });
    },
    onError: (error) => {
      setEnableTestFetch(false);
      toast.error(error?.message);
      if (testsTab.testsList.length > 1) {
        window.history.back(); // go back to the prev test
      } else {
        const uuid = generateRandomNewTest10Digits();
        navigate(`/projects/${activeProject?.id}/api-suites/new/${uuid}`);
      }
      // go back to the last test
    }
  });
  const handleRefetchTest = () => {
    refetchActiveTest();
  };
  //swtich to response headers tab
  return { singleTestFetched, isFetchingSingleTest, handleRefetchTest };
};
// this effect should try to run evrytime the url, or tabIndex changes

export const configureTestDocumentData = (testData) => {
  let testDocumentData = {
    url: '',
    method: '',
    reqParams: [],
    reqHeaders: [],
    reqBody: '',
    mode: '',
    authType: ''
  };
  const rawBodyTypes = ['plaintext', 'json', 'javascript', 'html', 'xml', 'graphql'];
  let { params, headers, authorization, url, request_method, mode, body } = testData;
  headers = convertNullValuesToEmptyString([...headers]);
  params = convertNullValuesToEmptyString([...params]);
  let authorizationHeader = JSON.parse(authorization).value;
  testDocumentData.reqBody = body;
  if (rawBodyTypes.includes(mode)) {
    if (mode === 'json') {
      testDocumentData.reqBody = JSON.stringify(body, null, 2); // it's saved on backend a js object but the editor expects a json string
    } else if (mode === 'graphql') {
      testDocumentData.reqBody = body;
    } else {
      testDocumentData.reqBody = body; // other raw types were already saved as strings
    }
  }
  testDocumentData.reqHeaders = headers;
  testDocumentData.reqParams = params;
  testDocumentData.authType = authorizationHeader?.option;
  testDocumentData.url = url;
  testDocumentData.method = request_method?.toUpperCase();
  testDocumentData.mode = mode;
  return testDocumentData;
};

export default useFetchSingleTest;
